import loadHockeySchedule from './components/hockey/loaders/loadHockeySchedule'
import CommuterRailPrediction from './components/mbta/CommuterRailPrediction'
import loadStations from './components/mbta/api-utils/loaders/loadStations'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import MbtaNavigation from './components/mbta/MbtaNavigation'
import HockeyHome from './components/hockey/HockeyHome'
import MbtaHome from './components/mbta/MbtaHome'
import Home from './components/Home'
import { Spin } from 'antd'
import React from 'react'
import './App.scss'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home/>
  },
  {
    path: '/hockey',
    element: <HockeyHome />,
    loader: loadHockeySchedule,
    id: 'hockey'
  },
  {
    path: '/mbta',
    element: <MbtaNavigation />,
    loader: loadStations,
    id: 'mbta',
    children: [
      {
        path: '/mbta/',
        element: <MbtaHome />
      }, {
        path: '/mbta/:stopName',
        element: <CommuterRailPrediction />
      }
    ]
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} fallbackElement={<Spin className="spinner" size="large" />}/>
    </div>
  );
}

export default App;
