import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import type { Event } from 'react-big-calendar'
import startOfWeek from 'date-fns/startOfWeek'
import enUS from 'date-fns/locale/en-US'
import format from 'date-fns/format'
import getDay from 'date-fns/getDay'
import parse from 'date-fns/parse'

interface Props {
  events: Event[]
}

const locales = {
  'en-US': enUS,
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

const HockeyCalendar = ({ events }: Props) => (
  <div>
    <Calendar
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      defaultView="week"
      style={{ height: window.innerHeight }}
    />
  </div>
)

export default HockeyCalendar
