import { useRouteLoaderData, useNavigate, useParams } from 'react-router-dom'
import initPredictionsStream from './api-utils/streams/predictions-stream'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import initVehicleStream from './api-utils/streams/vehicle-stream'
import initAlertStream from './api-utils/streams/alerts-stream'
import { StationList } from './api-utils/loaders/loadStations'
import fetchSchedule from './api-utils/requests/schedule'
import fetchRoutes from './api-utils/requests/routes'
import { Alert, Button, Result, Spin } from 'antd'
import AdSense, { AdSlots } from '../misc/AdSense'
import { setTitle } from '../utils/seo'
import { format } from 'date-fns'

interface PredictionAttributes {
  vehicle_id: string
  arrival_time: string
  departure_time: string
  status: string | null
  vehicle_status?: string
  stop?: string
}

type PredictionData = Record<string, PredictionAttributes>

interface AlertAttributes {
  header: string,
  severity: number
}

type AlertData = Record<string, AlertAttributes>

enum Actions {
  'IN_TRANSIT_TO' = 'In transit to',
  'STOPPED_AT' = 'Stopped at',
}

let interval: any
const controller: Record<string, AbortController> = {}

export default function CommuterRailPrediction() {
  const [predictions, setPredictions] = useState<PredictionData>({})
  const [schedule, setSchedule] = useState<Array<Date>>([])
  const [routes, setRoutes] = useState<Array<string>>([])
  const stops = useRouteLoaderData('mbta') as StationList
  const [alerts, setAlerts] = useState<AlertData>({})
  const [, setUpdate] = useState<Date>(new Date())
  const predictionLength = Object.values(predictions).length
  const home = decodeURIComponent(useParams()?.stopName ?? '')
  const navigate = useNavigate()

  const station = stops[home]
  const { stopIds } = station ?? {}

  useEffect(() => {
    setTitle(`Inbound from ${home}`)
  }, [home])

  useLayoutEffect(() => {
    if (stopIds) {
      const predictionSignal = new AbortController()

      fetchSchedule(stopIds.join(','), setSchedule)
      fetchRoutes(stopIds.join(','), setRoutes)
      initPredictionsStream(stopIds.join(','), onResetPrediction, onUpdatePrediction, predictionSignal.signal)

      return () => predictionSignal.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopIds])

  useLayoutEffect(() => {
    if (routes.length > 0) {
      const alertSignal = new AbortController()

      initAlertStream(routes, onResetAlert, onUpdateAlert, alertSignal.signal)

      return () => alertSignal.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes])

  useEffect(() => {
    if (predictionLength > 0) {
      interval = setInterval(() => setUpdate(new Date()), 1000)
      Object.values(predictions).forEach(prediction => {
        if (!controller[prediction.vehicle_id]) {
          controller[prediction.vehicle_id] = new AbortController()
          initVehicleStream(prediction.vehicle_id, onResetVehicle, onUpdateVehicle, controller[prediction.vehicle_id].signal)
        }
      })
    } else {
      if (interval) {
        clearInterval(interval)
      }
    }
    return () => interval && clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [predictionLength])

  if (!station) {
    return <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Button onClick={() => navigate('/mbta')} type="primary">Station list</Button>}
    />
  }


  const onResetPrediction = (body: any) => {
    const data: PredictionData = {}
    body.forEach((prediction: any) => {
      const vehicle_id = prediction.relationships.vehicle.data.id
      const existingPrediction = predictions[prediction.id] ?? {}
      data[prediction.id] = {
        ...existingPrediction,
        vehicle_id,
        arrival_time: prediction.attributes.arrival_time,
        departure_time: prediction.attributes.departure_time,
        status: prediction.attributes.status,
      }
    })
    setPredictions(data)
  }

  const onUpdatePrediction = (body: any) => {
    const newPrediction = {...predictions[body.id]}
    newPrediction.arrival_time = body.attributes.arrival_time
    newPrediction.departure_time = body.attributes.departure_time
    newPrediction.status = body.attributes.status
    setPredictions(predictions => ({ ...predictions, [body.id]: newPrediction }))
  }

  const onResetVehicle = (body: any) => {
    onUpdateVehicle(body[0])
  }

  const onUpdateVehicle = (body: any) => {
    let predictionExists = false
    Object.entries(predictions).forEach(([id, prediction]) => {
      if (prediction.vehicle_id === body.id) {
        predictionExists = true
        const newPrediction = {
          ...prediction,
          vehicle_status: Actions[body.attributes.current_status as keyof typeof Actions],
          stop: stops[body.relationships.stop.data.id]?.name ?? ''
        }
        setPredictions(predictions => ({ ...predictions, [id]: newPrediction }))
      }
    })
    if (!predictionExists && body.type === 'vehicle') {
      controller[body.id]?.abort()
      delete controller[body.id]
    }
  }

  const onResetAlert = (body: any) => {
    const newAlerts: AlertData = {}
    body.forEach((alert: any) => {
      newAlerts[alert.id] = {
        header: alert.attributes.header,
        severity: alert.attributes.severity,
      }
    })
    setAlerts(newAlerts)
  }

  const onUpdateAlert = (body: any) => {
    setAlerts(alerts => { return { ...alerts, [body.id]: {
        header: body.attributes.header,
        severity: body.attributes.severity,
      }}})
  }

  if (Object.values(stops).length === 0) {
    return <Spin className="spinner" size="large"/>
  }

  let noPredictionMessage
  if (Object.values(predictions).length === 0)
    noPredictionMessage = <p>No upcoming predictions available.</p>

  const displayPredictions = Object.values(predictions).map((prediction, idx) => {
    const adjective = idx ? 'following' : 'next'

    if (!prediction.departure_time)
      return <></>

    const now = new Date()
    const station = new Date(prediction.arrival_time ?? prediction.departure_time)
    // @ts-ignore
    const seconds = (station - now) / 1000
    const preface = `The ${adjective} train`

    const currentStatus = () => {
      if (seconds <= 90 && prediction.vehicle_status === 'Stopped at' && prediction.stop === home)
        return <span>{`${preface} is now boarding at ${home}`}</span>

      if (seconds <= 30)
        return <span>{`${preface} is now arriving at ${home}`}</span>

      if (seconds <= 60)
        return <span>{`${preface} is now approaching ${home}`}</span>

      const minutes = Math.round(seconds / 60)
      return <span>{`${preface} arrives in ${minutes} minute${minutes === 1 ? '' : 's'}`}</span>
    }

    return <p key={idx}>
      {currentStatus()}
      {prediction.vehicle_status && prediction.stop &&
        ` (${prediction.vehicle_status} ${prediction.stop})`
      }
      {prediction.status && ` — ${prediction.status}`}
    </p>
  })

  const displaySchedule = schedule.filter(trip => trip > new Date())
    .map((trip, idx) => {
    return <p key={idx}>{format(trip, 'h:mm aaa')}</p>
  })

  const displayAlerts = Object.values(alerts).map((alert, idx) => {
    return <Alert key={idx} type={alert.severity === 1 ? 'info' : 'warning'} message={alert.header} />
  })

  return (
    <div className="mbta-prediction-container">
      <div className="background-image" />
      <h1>{`Inbound from ${home.replace('/', ' / ')}`}</h1>
      {displayAlerts}
      {noPredictionMessage && noPredictionMessage}
      {displayPredictions}
      <AdSense slot={AdSlots.MBTA} />
      <h2>Scheduled</h2>
      {displaySchedule}
    </div>
  )
}
