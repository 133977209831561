import { fetchEventSource } from '@microsoft/fetch-event-source';

const initVehicleStream = (vehicleId: string, onReset: (data: any) => void, onUpdate: (data: any) => void, signal: AbortSignal) => {
  void fetchEventSource(`https://api-v3.mbta.com/vehicles?fields%5Bvehicle%5D=current_status&filter%5Bid%5D=${vehicleId}`, {
    headers: {
      'x-api-key': process.env.REACT_APP_MBTA_KEY ?? '',
      accept: 'text/event-stream',
    },
    signal,
    onmessage(event) {
      const body = JSON.parse(event.data)
      switch (event.event) {
        case 'reset':
          onReset(body)
          break
        case 'update':
          onUpdate(body)
          break
        default:
          console.log(event)
      }
    },
  });
}

export default initVehicleStream
