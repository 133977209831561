import { useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'
import { Spin } from 'antd'

export default function Home() {
  const navigate = useNavigate()

  useEffect(() => navigate('/mbta'), [navigate])

  return (
    <Spin className="spinner" size="large" />
  )
}
