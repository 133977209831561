import { HockeyEvents } from './loaders/loadHockeySchedule'
import { useRouteLoaderData } from 'react-router-dom'
import HockeyCalendar from './HockeyCalendar'
import React from 'react'

export default function HockeyHome() {
  const schedule = useRouteLoaderData('hockey') as HockeyEvents
  return (
    <>
      <HockeyCalendar events={schedule.hockey} />
    </>
  )
}
