import { fetchEventSource } from '@microsoft/fetch-event-source';

const initPredictionsStream = (stopId: string, onReset: (data: any) => void, onUpdate: (data: any) => void, signal: AbortSignal) => {
  void fetchEventSource('https://api-v3.mbta.com/predictions' +
    '?sort=arrival_time' +
    '&fields%5Bprediction%5D=status%2Carrival_time%2Cdeparture_time' +
    '&filter%5Bdirection_id%5D=1' +
    `&filter%5Bstop%5D=${stopId}`, {
    headers: {
      'x-api-key': process.env.REACT_APP_MBTA_KEY ?? '',
      accept: 'text/event-stream',
    },
    signal,
    onmessage(event) {
      const body = JSON.parse(event.data)
      switch (event.event) {
        case 'reset':
          onReset(body)
          break
        case 'update':
          onUpdate(body)
          break
        default:
          console.log(event)
      }
    },
  });
}

export default initPredictionsStream
