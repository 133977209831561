import React from 'react'

const fetchRoutes = (stopId: string, setRoutes: React.Dispatch<string[]>) => {
  fetch('https://api-v3.mbta.com/routes' +
    '?sort=long_name' +
    '&fields%5Broute%5D=long_name%2Cdirection_names%2Cdirection_destinations' +
    `&filter%5Bstop%5D=${stopId}` +
    '&filter%5Btype%5D=2', {
    headers: {
      'x-api-key': process.env.REACT_APP_MBTA_KEY ?? '',
      accept: 'application/vnd.api+json',
    }
  })
    .then(response => {
      if (response.ok) {
        return response;
      } else {
        let errorMessage = `${response.status} (${response.statusText})`,
          error = new Error(errorMessage);
        throw(error);
      }
    })
    .then(response => {
      return response.json();
    })
    .then(body => {
      const stops = body.data.map((route: any) => {
        return route.id
      })
      setRoutes(stops)
    })
    .catch(error => console.error(`Error in fetch: ${error.message}`))
}

export default fetchRoutes
