import { StationList } from './api-utils/loaders/loadStations'
import { Link, useRouteLoaderData } from 'react-router-dom'
import { setDescription, setTitle } from '../utils/seo'
import React, { useEffect } from 'react'
import { List } from 'antd'

export default function MbtaHome() {
  const stops = useRouteLoaderData('mbta') as StationList

  useEffect(() => {
    setTitle('Inbound to Boston')
    setDescription('Simple mobile web app with MBTA Commuter Rail predictions, schedules, and alerts; focused on your home station inbound to Boston.')
  }, [])

  return (
    <div className="mbta-station-list">
      <List
        header={<h1>Stations</h1>}
        footer={<span>Copyright © 2023 Dichroic Software, LLC. All rights reserved.</span>
        }
        bordered
        dataSource={Object.values(stops)}
        renderItem={(stop) => (
          <List.Item>
            <Link to={`/mbta/${encodeURIComponent(stop.name)}`}>{stop.name}</Link>
          </List.Item>
        )}
      />
    </div>
  )
}
