import { fetchEventSource } from '@microsoft/fetch-event-source';

const initAlertStream = (routes: Array<string>, onReset: (data: any) => void, onUpdate: (data: any) => void, signal: AbortSignal) => {
  void fetchEventSource('https://api-v3.mbta.com/alerts' +
    '?sort=created_at' +
    '&fields%5Balert%5D=header%2Cseverity' +
    '&filter%5Bactivity%5D=BOARD%2CEXIT%2CRIDE' +
    '&filter%5Bdirection_id%5D=1' +
    `&filter%5Broute%5D=${routes.join('%2C')}`, {
    headers: {
      'x-api-key': process.env.REACT_APP_MBTA_KEY ?? '',
      accept: 'text/event-stream',
    },
    signal,
    onmessage(event) {
      const body = JSON.parse(event.data)
      switch (event.event) {
        case 'reset':
          onReset(body)
          break
        case 'update':
          onUpdate(body)
          break
        default:
          console.log(event)
      }
    },
  });
}

export default initAlertStream
