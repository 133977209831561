import { Outlet } from 'react-router-dom'
import React from 'react'

export default function MbtaNavigation() {
  return (
    <>
      {/*Navigation hamburger*/}
      <Outlet />
    </>
  )
}
