import React, { useEffect } from 'react'

export const AdSlots = {
  MBTA: '1124052746'
} as const

declare let window: any

type Props = {
  slot: string
}

const displayAds = false

export default function AdSense({ slot }: Props) {
  useEffect(() => {
    displayAds && (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, [])

  return (<>
    {displayAds &&
      <ins className="adsbygoogle"
           style={{display: 'block', textAlign: 'center'}}
           data-ad-client="ca-pub-7747840977810912"
           data-ad-slot={slot}
           data-ad-format="fluid"
           data-ad-layout="in-article"
      />
    }
  </>)
}
