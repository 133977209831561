import React from 'react'
import { format } from 'date-fns'

const fetchSchedule = (stopId: string, setSchedule: React.Dispatch<Array<Date>>) => {
  const date = format(new Date(), 'yyyy-MM-dd')

  fetch('https://api-v3.mbta.com/schedules' +
    '?sort=departure_time' +
    '&fields%5Bschedule%5D=departure_time' +
    '&filter%5Bdirection_id%5D=1' +
    `&filter%5Bdate%5D=${date}` +
    `&filter%5Bstop%5D=${stopId}`, {
    headers: {
      'x-api-key': process.env.REACT_APP_MBTA_KEY ?? '',
      accept: 'application/vnd.api+json',
    }
  })
    .then(response => {
      if (response.ok) {
        return response;
      } else {
        let errorMessage = `${response.status} (${response.statusText})`,
          error = new Error(errorMessage);
        throw(error);
      }
    })
    .then(response => {
      return response.json();
    })
    .then(body => {
      const schedule = body.data.map((trip: any) => {
        return new Date(trip.attributes.departure_time)
      })
      setSchedule(schedule)
    })
    .catch(error => console.error(`Error in fetch: ${error.message}`))
}

export default fetchSchedule
