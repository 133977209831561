import type { Event } from 'react-big-calendar'
import parse from 'date-fns/parse'
import { addMonths, format } from 'date-fns'

export type HockeyEvents = {
  hockey: Event[],
  skating: Event[],
}

const parseDate = (date: string, time: string): Date =>
  parse(`${date} ${time}`, 'yyyy-MM-dd HH:mm', new Date())

const formatFmc = (date: string, events: any[], filter: string): Array<Event> => {
  return events.filter((event: any) => event.eventName.toLowerCase() === filter).map(event => {
    return {
      title: event.calendarLabel.replace('- Ice Sheet', '(FMC)'),
      start: parseDate(date, event.usageStartTime),
      end: parseDate(date, event.usageEndTime),
    }
  })
}

const loadHockeySchedule = async (): Promise<HockeyEvents> => {
  const today = new Date()
  const start = format(today, 'yyyy-MM-dd')
  const end = format(addMonths(today, 3), 'yyyy-MM-dd')
  return await fetch('https://fmc.myhalix.io/event/sandboxes/sbx~00~300/scope/business/biz~00~-wcAAAAAAAA~AQA/publicEvents' +
    `?startDate=${start}&endDate=${end}`, {
    method: 'POST',
    body: JSON.stringify({ eventTypes: ['booking'] }),
  })
    .then(response => {
      if (response.ok) {
        return response;
      } else {
        let errorMessage = `${response.status} (${response.statusText})`,
          error = new Error(errorMessage);
        throw(error);
      }
    })
    .then(response => {
      return response.json();
    })
    .then(body => {
      const events: HockeyEvents = {
        hockey: [],
        skating: [],
      }
      body.dayInfo.forEach((info: any) => {
        const { date, eventInfo } = info
        events.hockey = events.hockey.concat(formatFmc(date, eventInfo, 'public hockey'))
        events.skating = events.skating.concat(formatFmc(date, eventInfo, 'public skating'))
      })
      return events
    })
    .catch(error => {
      console.error(`Error in fetch: ${error.message}`)
      return {
        hockey: [],
        skating: []
      }
    })
}

export default loadHockeySchedule
