export type Station = {
  name: string,
  stopIds: string[],
}

export type StationList = Record<string, Station>

const loadStations = async (): Promise<StationList> => {
  return await fetch('https://api-v3.mbta.com/stops' +
    '?sort=name' +
    '&fields%5Bstop%5D=name%2Cdescription' +
    '&include=route' +
    '&filter%5Broute_type%5D=2', {
    headers: {
      'x-api-key': process.env.REACT_APP_MBTA_KEY ?? '',
      accept: 'application/vnd.api+json',
    }
  })
    .then(response => {
      if (response.ok) {
        return response;
      } else {
        let errorMessage = `${response.status} (${response.statusText})`,
          error = new Error(errorMessage);
        throw(error);
      }
    })
    .then(response => {
      return response.json();
    })
    .then(body => {
      const stops: Record<string, Station> = {}
      body.data.forEach((stop: any) => {
        const id = stop.id.replace('place-', '') as string
        const name = stop.attributes.name
        if (stops[name]) {
          stops[name].stopIds.push(id)
        } else {
          stops[name] = {
            name,
            stopIds: [id]
          }
        }
      })
      return stops
    })
    .catch(error => {
      console.error(`Error in fetch: ${error.message}`)
      return {}
    })
}

export default loadStations
